import errorHandler from '../../services/error_handler';
import { isADate, isAString } from '../../utility/types';
import { isWindowDefined } from '../window';

// memoized flag
let _cookiesSupported = null;

/**
 * Low-level helpers
 */
function _documentCookieGet() {
  return document.cookie;
}

function _documentCookieSet(val) {
  document.cookie = val;
}

function _navigatorCookieEnabled() {
  return window.navigator.cookieEnabled;
}

/**
 * Internal getter/setter
 */
function _delete(key) {
  const cookie = _get(key);
  _documentCookieSet(`${key}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=.${CLIENT_ENV.DEFAULT_DOMAIN};`);

  return cookie;
}

function _get(key) {
  const result = _documentCookieGet().match(new RegExp(`${key}=([^;]+)`));
  if (!result || !result[1]) return null;

  try {
    const test = JSON.parse(decodeURIComponent(result[1]));
    return test;
  } catch (error) {
    errorHandler(`parseCookie: ${key}`, error);
    return {};
  }
}

function _set(key, value, expires) {
  const encodedVal = isAString(value) ? value : encodeURIComponent(JSON.stringify(value));
  // if no expires value is passed in we will default to Session.
  const date = isADate(expires) ? expires.toUTCString() : null;

  _documentCookieSet(`${key}=${encodedVal}; path=/; domain=.${CLIENT_ENV.DEFAULT_DOMAIN}; expires=${date}`);
}

/**
 * Public methods
 */
export function areCookiesSupported() {
  if (_cookiesSupported === null) {
    if (isWindowDefined && _navigatorCookieEnabled()) {
      _set('testcookie', '1');
      const cookie = _get('testcookie');

      if (cookie) {
        _delete('testcookie');
      }

      _cookiesSupported = !!cookie;
    } else {
      _cookiesSupported = false;
    }
  }

  return _cookiesSupported;
}

export function deleteCookie(key) {
  return areCookiesSupported() ? _delete(key) : null;
}

export function getCookie(key) {
  return areCookiesSupported() ? _get(key) : null;
}

export function setCookie(key, value, expires) {
  return areCookiesSupported() ? _set(key, value, expires) : null;
}
